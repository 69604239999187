import React from 'react';
import BaseTemplate from '../../../components/templates/BaseLayout';
import SEO from '../../../components/atoms/utility/seo';
import Spacer from '../../../components/atoms/utility/Spacer';
import EventsListBlogV2 from '../../../components/organisms/EventsListBlogV2';
import { grandRapidsEvents } from '../../../content/events/grand-rapids-events';

function GrandRapidsDaytime() {
    return (
        <BaseTemplate>
            <SEO
                title={'Daytime Activities Grand Rapids'}
                description={
                    'Come find events to do during the day in Grand Rapids'
                }
            />
            <Spacer sizeY={1} className="" />
            <EventsListBlogV2
                cityName="Grand Rapids"
                categoryPage={'Daytime'}
                eventsData={grandRapidsEvents}
            />
            <Spacer sizeY={1} className="" />
        </BaseTemplate>
    );
}

export default GrandRapidsDaytime;
